
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    isPdf: Boolean,
  },
})
export default class Footer extends Vue {

}
