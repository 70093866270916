
import { Options, Vue } from "vue-class-component";
import Navbar from "../../features/static/components/Navbar.vue";
import Hero from "../../features/static/components/Hero.vue";
import Footer from "../../features/static/components/Footer.vue";

@Options({
  components: { Hero, Navbar, Footer },
  props: { },
})
export default class AboutUsView extends Vue {

}
