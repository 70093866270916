import {
  IQuotationModel,
  ServicePackFrequency
} from "../../../../features/quotation/QuotationModel";

export const ABQT1004: IQuotationModel = {
  quotationNumber: "ABQT-1004",
  quotationDate: '20th Sept 2023',
  clientInfo: {
    name: "Utsv Bake Gourmet",
    phone: "+91-8921343980"
  },
  gstPercent: 18,
  appliedOfferCode: "LAUNCH-1000",
  servicePacks: [
    {
      packName: "Organic Marketing Pack",
      frequency: ServicePackFrequency.Monthly,
      items: [
        {
          serviceName: "Handling Service",
          description: "This service covers managing all your social media accounts (like Instagram, Facebook, LinkedIn, and YouTube). We'll post content every other day to ensure a consistent and active online presence on these platforms.",
          price: 8000
        }
      ]
    }
  ]
}
