import { IPricingItemModel } from "../PricingModel";

export const digitalMediaPricing: IPricingItemModel[] = [
  //{ title: "", caption: "", lite: "10000", standard: "15000", premium: "25000", isPrice: true },
  { title: "Payment Frequency", caption: "", lite: "Monthly", standard: "Monthly", premium: "Monthly" },
  { title: "Handle Optimization", caption: "", lite: "Free", standard: "Free", premium: "Free" },
  { title: "HashTags Optimization", caption: "", lite: "Free", standard: "Free", premium: "Free" },
  { title: "Design & Posts", caption: "", lite: "2 per week", standard: "Every alternate days", premium: "Every alternate days" },
  { title: "Festival Posters", caption: "", lite: "no", standard: "yes", premium: "yes" },
  { title: "Video Posts", caption: "", lite: "no", standard: "1 per week", premium: "2 per week" },
  { title: "Google Analytics", caption: "", lite: "no", standard: "yes", premium: "yes" },
  { title: "SEO Setup", caption: "", lite: "no", standard: "no", premium: "yes" },
  { title: "Community Engagement", caption: "", lite: "no", standard: "no", premium: "yes" }
]


export const staticWebsitePricing: IPricingItemModel[] = [
  //{ title: "", caption: "", lite: "15000", standard: "28000", premium: "34000", isPrice: true },
  { title: "Payment Frequency", caption: "", lite: "One-Time", standard: "One-Time", premium: "One-Time" },
  { title: "Hosting Setup", caption: "", lite: "yes", standard: "yes", premium: "yes" },
  { title: "Mobile Friendly Design", caption: "", lite: "yes", standard: "yes", premium: "yes" },
  { title: "Business Information Content", caption: "", lite: "yes", standard: "yes", premium: "yes" },
  { title: "Product/Service Information Content", caption: "", lite: "yes", standard: "yes", premium: "yes" },
  { title: "Contact Us Details", caption: "Including social media links", lite: "yes", standard: "yes", premium: "yes" },
  { title: "Banner Design", caption: "", lite: "One", standard: "Two", premium: "Four" },
  { title: "Video Banners Design", caption: "", lite: "no", standard: "One", premium: "Three" },
  { title: "Google Map Integration", caption: "", lite: "no", standard: "yes", premium: "yes" },
  { title: "Future Upgradable", caption: "", lite: "no", standard: "yes", premium: "yes" },
  { title: "Future API Integration", caption: "", lite: "no", standard: "yes", premium: "yes" },
  { title: "Google Analytics", caption: "", lite: "no", standard: "no", premium: "yes" },
  { title: "Form Submission", caption: "", lite: "no", standard: "no", premium: "yes" }
]
