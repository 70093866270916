
import { Options, Vue } from "vue-class-component";
import Navbar from "../../features/static/components/Navbar.vue";
import Footer from "../../features/static/components/Footer.vue";
import PricingGrid from "./components/PricingGrid.vue";
import { staticWebsitePricing } from "./_static/Pricing";

@Options({
  methods: {
    staticWebsitePricing() {
      return staticWebsitePricing
    }
  },
  components: { PricingGrid, Navbar, Footer },
  props: { },
})
export default class StaticPricingView extends Vue {

}
