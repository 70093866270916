
import { Options, Vue } from "vue-class-component";
import Navbar from "../../features/static/components/Navbar.vue";
import Footer from "../../features/static/components/Footer.vue";
import PricingGrid from "./components/PricingGrid.vue";
import { digitalMediaPricing } from "./_static/Pricing";

@Options({
  methods: {
    digitalMediaPricing() {
      return digitalMediaPricing
    }
  },
  components: { PricingGrid, Navbar, Footer },
  props: { },
})
export default class DigitalMediaPricingView extends Vue {

}
