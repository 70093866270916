
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    isNavLight: {
      type: Boolean,
      default: true
    },
    isLogoDark: {
      type: Boolean,
      default: false
    },
    hideMenuItems: Boolean,
    activeLink: {
      type: String,
      default: "home"
    },
    fullLink: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    window.onscroll = function () {
      onWindowScroll();
    };

    let navbar = document.getElementById("navbar");
    let navSections = document.getElementsByClassName("nav-section");
    let navLinks = document.getElementsByClassName("nav-link");
    function onWindowScroll() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        navbar?.classList.add("nav-sticky");
      } else {
        navbar?.classList.remove("nav-sticky");
      }

      const headerHeight = 114;
      if(navSections && navSections.length > 0 && navLinks && navLinks.length > 0) {
        for (let i=0; i < navSections.length; i++) {
          const sec: any = navSections[i];
          if(!sec) continue;
          const scrollTop = document.documentElement.scrollTop + headerHeight;
          for (let j=0; j < navLinks.length; j++) {
            const link: any = navLinks[i];
            if(!link) continue;
            link.classList.remove(...['active']);
            if(scrollTop >= sec.offsetTop
              && scrollTop <= (sec.offsetTop + sec.offsetHeight)
              && link.href.indexOf(`#${sec.id}`) > -1) {
              link.classList.add('active');
            }
          }
        }
      }
    }
  },
  methods: {
    toggleMenu() {
      const navbarCollapse = document.getElementById("navbarCollapse");
      navbarCollapse?.classList.toggle("show");
    },
    getLink(name: String) {
      if(name == "home")
        return this.fullLink ? '/home' : '#home';

      if(name == "services")
        return this.fullLink ? '/services' : '#home';

      if(name == "features")
        return this.fullLink ? '/features' : '#home';

      if(name == "contact")
        return this.fullLink ? '/contact' : '#home';
    }
  }
})
export default class Navbar extends Vue {

}
