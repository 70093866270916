import {
  IQuotationModel,
  ServicePackFrequency
} from "../../../../features/quotation/QuotationModel";

export const ABQT1002: IQuotationModel = {
  quotationNumber: "ABQT-1002",
  quotationDate: '13th Sept 2023',
  clientInfo: {
    name: "Absquar Solutions",
    phone: "+91-8618795392",
    email: "info@absquar.com",
  },
  gstPercent: 18,
  appliedOfferCode: "LAUNCH-2000",
  servicePacks: [
    {
      packName: "Dynamic Hosting",
      frequency: ServicePackFrequency.Yearly,
      items: [
        {
          serviceName: "Web Hosting",
          description: "This service provides hosting for your website, along with managing the API and database to handle a good amount of user traffic. We'll link your domain to this hosting space. If you don't have a domain, we can assist you in acquiring one at the standard market price.",
          price: 6000
        }
      ]
    },
    {
      packName: "MVP Dynamic Pack",
      frequency: ServicePackFrequency.OneTime,
      items: [
        {
          serviceName: "Designing Service",
          description: "This service involves designing your project according to your needs. We invest time and effort to ensure the best user experience based on your requirements.",
          price: 10000
        },
        {
          serviceName: "User Interface Architecture & Setup Service",
          description: "This service involves researching and selecting the best user interface technology for your website. We usually choose from Angular, React, or Vue.js because they are highly popular and versatile. We consider future needs, especially if you plan to develop a mobile app later on.",
          price: 15000
        },
        {
          serviceName: "API Architecture & Setup Service",
          description: "This service involves creating the backbone of your website's data interaction, known as the API infrastructure. We typically use .NET Core, a popular choice due to its wide adoption. The great thing about it is that it can run on both Windows and Linux servers. Having a robust API is crucial as it allows for seamless integration with mobile apps you might develop later.",
          price: 20000
        },
        {
          serviceName: "Database Design & Setup Service",
          description: "This service includes creating a database tailored to your needs. It's crucial to set up a solid database structure from the start, ensuring it's easy to maintain and long-lasting.",
          price: 10000
        }
      ]
    },
    {
      packName: "Dynamic Maintenance Pack",
      frequency: ServicePackFrequency.Monthly,
      items: [
        {
          serviceName: "General Maintenance Service",
          description: "This service handles fixing any issues (bugs) in the project without making major changes to how the project is built. We primarily focus on resolving problems within the existing structure.",
          price: 10000
        }
      ]
    }
  ],
  addOnServicePacks: [
    {
      packName: "Dynamic Maintenance Pack",
      frequency: ServicePackFrequency.Monthly,
      items: [
        {
          serviceName: "General Maintenance Service",
          description: "This service handles fixing any issues (bugs) in the project without making major changes to how the project is built. We primarily focus on resolving problems within the existing structure.",
          price: 10000
        },
        {
          serviceName: "Feature Addition Service",
          description: " This service covers creating a particular feature as per your needs and integrating it smoothly into your current project setup. The charges includes all the development work for the website, API, and database modifications.",
          price: 25000
        }
      ]
    }
  ]
}
