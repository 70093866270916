import { IQuotationMappingModel } from "../QuotationModel";
import { ABQT1001 } from "./quotations/ABQT-1001";
import { ABQT1002 } from "./quotations/ABQT-1002";
import { ABQT1003 } from "./quotations/ABQT-1003";
import { ABQT1004 } from "./quotations/ABQT-1004";
import { ABQT1005 } from "./quotations/ABQT-1005";
import { ABQT1006 } from "./quotations/ABQT-1006";
import { ABQT1007 } from "./quotations/ABQT-1007";
import { ABQT1008 } from "./quotations/ABQT-1008";

export const quotationMapping: IQuotationMappingModel[] = [
  { urlId: 'ABQT-1001-8618795392', quotation: ABQT1001 },
  { urlId: 'ABQT-1002-8618795392', quotation: ABQT1002 },
  { urlId: 'ABQT-1003-8618795392', quotation: ABQT1003 },
  { urlId: 'ABQT-1004-8921343980', quotation: ABQT1004 },
  { urlId: 'ABQT-1005-5473726070', quotation: ABQT1005 },
  { urlId: 'ABQT-1006-1701105480', quotation: ABQT1006 },
  { urlId: 'ABQT-1007-1801205480', quotation: ABQT1007 },
  { urlId: 'ABQT-1008-1707202960', quotation: ABQT1008 }
]
