import { IQuotationModel, ServicePackFrequency } from "../../../../features/quotation/QuotationModel";

let ServiceFrequency;
export const ABQT1005: IQuotationModel = {
  quotationNumber: "ABQT-1005",
  quotationDate: '24th Sept 2023',
  clientInfo: {
    name: "Al Bayader Organic Fertilizer Factory",
    phone: "+971 547372607",
    email: "organic@amirates.ae",
  },
  gstPercent: 0,
  appliedOfferCode: "LAUNCH-2000",
  servicePacks: [
    {
      packName: "Static Hosting Pack",
      frequency: ServicePackFrequency.Yearly,
      items: [
        {
          serviceName: "Web Hosting Service",
          description: "This service provides enough room on the internet for your website and can handle a good number of visitors. We'll link your website address (domain) to this space. If you don't have a website address, we can assist you in getting one at the regular market price.",
          price: 5000
        }
      ]
    },
    {
      packName: "MVP Static Pack",
      frequency: ServicePackFrequency.OneTime,
      items: [
        {
          serviceName: "Website Designing Service",
          description: "This service includes creating a fitting design for your company's website. This design will feature fundamental information about your company, highlighting your products, and incorporating videos, banners, and images that align with your logo's color scheme. The website will provide comprehensive \"about us\" details along with easily accessible contact information.",
          price: 9000
        },
        {
          serviceName: "Website User Interface Service",
          description: "This includes building your entire website from the ground up using the latest and most popular technology stack. We'll handle everything from start to finish.",
          price: 14000
        }
      ]
    }
  ],
  addOnServicePacks: [
    {
      packName: "Maintenance Standard Pack",
      frequency: ServicePackFrequency.Monthly,
      items: [
        {
          serviceName: "General Maintenance Service",
          description: "This service covers updating only the content, banners, and images on the website.",
          price: 6000
        }
      ]
    },
    {
      packName: "Maintenance Premium Pack",
      frequency: ServicePackFrequency.Monthly,
      items: [
        {
          serviceName: "General Maintenance Service",
          description: "This service covers updating only the content, banners, and images on the website.",
          price: 6000
        },
        {
          serviceName: "Feature Addition Service",
          description: "This service is for making significant changes to how your website looks and feels. It's recommended if you want to give your website a new and different appearance.",
          price: 10000
        }
      ]
    }
  ]
}
