
import { Options, Vue } from "vue-class-component";
import emailjs from 'emailjs-com';

@Options({
  props: { },
})
export default class Contact extends Vue {
  enquiryFormLoading: boolean = false;
  enquiryFormSubmit = (e: any) => {
    e.preventDefault();

    const serviceId = 'abs_enquiry';
    const templateId = 'abs_enquiry_form';
    const publicKey = 'BmhIiEvmwgdU0iIF6';
    this.enquiryFormLoading = true;
    emailjs.sendForm(serviceId, templateId, e.target, publicKey)
      .then(() => {
        e.target['name'].value = "";
        e.target['email'].value = "";
        e.target['phone'].value = "";
        e.target['subject'].value = "";
        e.target['message'].value = "";
        e.target['submit'].style = "";

        this.enquiryFormLoading = false;
        alert('Your enquiry is sent to the Absquar Team, Our team will reach out to you shortly');
      }, (err) => {
        this.enquiryFormLoading = false;
        console.log(JSON.stringify(err));
        alert('Something went wrong while submitting the form. Feel free to reach out to us.');
      });

    return false;
  }
}
