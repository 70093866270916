export interface IQuotationMappingModel {
  urlId: string,
  quotation: IQuotationModel
}

export interface IQuotationModel {
  quotationNumber: string,
  quotationDate: string,
  clientInfo: IQuotationClientModel,
  expectedDaysOfWork?: number,
  gstPercent: number,
  appliedOfferCode?: string,
  servicePacks: IQuotationServicePackModel[],
  addOnServicePacks?: IQuotationServicePackModel[]
}

export interface IQuotationClientModel {
  name: string,
  email?: string,
  phone?: string
}

export interface IQuotationServicePackModel {
  packName: string,
  items: IQuotationServiceModel[],
  frequency: ServicePackFrequency
}

export interface IQuotationServiceModel {
  serviceName: string,
  description?: string,
  price: number
}

export enum ServicePackFrequency {
  OneTime,
  Monthly,
  Yearly
}

/*export enum QuotationType {
  Static,
  Dynamic,
  DigitalMarketing
}*/
