
export interface IOfferModel {
  offerCode: string,
  offerBasedOn: OfferBasedOn,
  offeredFigure: number
}

export enum OfferBasedOn {
  Percentage,
  FlatAmount
}
