import { IQuotationModel, ServicePackFrequency } from "../../../../features/quotation/QuotationModel";

let ServiceFrequency;
export const ABQT1001: IQuotationModel = {
  quotationNumber: "ABQT-1001",
  quotationDate: '13th Sept 2023',
  clientInfo: {
    name: "Absquar Solutions",
    phone: "+91-8618795392",
    email: "info@absquar.com",
  },
  gstPercent: 18,
  appliedOfferCode: "LAUNCH-2000",
  servicePacks: [
    {
      packName: "Static Hosting Pack",
      frequency: ServicePackFrequency.Yearly,
      items: [
        {
          serviceName: "Web Hosting Service",
          description: "This service provides enough room on the internet for your website and can handle a good number of visitors. We'll link your website address (domain) to this space. If you don't have a website address, we can assist you in getting one at the regular market price.",
          price: 3500
        }
      ]
    },
    {
      packName: "MVP Static Pack",
      frequency: ServicePackFrequency.OneTime,
      items: [
        {
          serviceName: "Designing Service",
          description: "This offers the design of different banners and images that match your logo's color palette.",
          price: 4500
        },
        {
          serviceName: "Website User Interface Service",
          description: "This includes building your entire website from the ground up using the latest and most popular technology stack. We'll handle everything from start to finish.",
          price: 7500
        }
      ]
    },
    {
      packName: "Static Maintenance Pack",
      frequency: ServicePackFrequency.Monthly,
      items: [
        {
          serviceName: "General Maintenance Service",
          description: "This service covers updating only the content, banners, and images on the website.",
          price: 3000
        }
      ]
    }
  ],
  addOnServicePacks: [
    {
      packName: "Static Maintenance Pack",
      frequency: ServicePackFrequency.Monthly,
      items: [
        {
          serviceName: "General Maintenance Service",
          description: "This service covers updating only the content, banners, and images on the website.",
          price: 3000
        },
        {
          serviceName: "Feature Addition Service",
          description: "This service is for making significant changes to how your website looks and feels. It's recommended if you want to give your website a new and different appearance.",
          price: 5000
        }
      ]
    }
  ]
}
