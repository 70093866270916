<script>
import { Options, Vue } from "vue-class-component";
import Navbar from "../../features/static/components/Navbar.vue";
import Footer from "../../features/static/components/Footer.vue";
import { IQuotationModel, ServicePackFrequency } from "../../features/quotation/QuotationModel";
import { QuotationService } from "../../features/quotation/QuotationService";
import { OfferBasedOn } from "../../features/offers/OfferModel";
import { Filters } from "../../utils/Filters";

@Options({
  computed: {
    Filters() {
      return Filters
    },
    ServicePackFrequency() {
      return ServicePackFrequency
    },
    appliedOffer() {
      if(this.quotationData == null || this.quotationData.appliedOfferCode == "") return null;
      return  QuotationService.GeOffer(this.quotationData.appliedOfferCode);
    },
    appliedOfferAmount() {
      if(this.appliedOffer == null) return 0;

      if(this.appliedOffer.offerBasedOn == OfferBasedOn.Percentage) {
        return (this.quotationExclTax * this.appliedOffer.offeredFigure) / 100;
      }
      else if(this.appliedOffer.offerBasedOn == OfferBasedOn.FlatAmount) {
        return this.appliedOffer.offeredFigure;
      }
      return 0;
    },
    quotationExclTax() {
      if(this.quotationData == null) return 0;

      return this.quotationData.servicePacks
        .reduce((packSum, packCurrent) => packSum + packCurrent.items.reduce((sum, current) => sum + current.price, 0), 0);
    },
    quotationExclTaxDiscounted() {
      if(this.quotationExclTax == null || this.quotationExclTax <= 0) return 0;

      return this.quotationExclTax - this.appliedOfferAmount;
    },
    quotationTax() {
      if(this.quotationExclTax == null || this.quotationExclTax <= 0) return 0;
      return (this.quotationExclTax * this.quotationData.gstPercent)/100;
    },
    finalPrice() {
      return this.quotationExclTaxDiscounted + this.quotationTax;
    }
  },
  components: {
    Navbar,
    Footer
  },
  props: {
    id: String
  },
  data() {
    return {
      quotationData: IQuotationModel | null
    }
  }
})
export default class QuotationPdfView extends Vue {
  mounted() {
    if(this.id != "") {
      this.quotationData = QuotationService.GetMappedQuotation(this.id);
    }
  }
}
</script>

<template>
  <div>
    <div>
      <section v-if="quotationData" id="quotation" class="section quotation pdf-view">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-12">
              <h2 class="quotation--number">
                Quotation # {{quotationData.quotationNumber}}
                <span class="quotation--date">{{quotationData.quotationDate}}</span>
              </h2>

            </div>
          </div>
          <div v-if="quotationData.clientInfo" class="client-info justify-content-center">
            <div class="row">
              <div class="col-sm-12">
                <h2 class="client--header">Customer</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 client-info--details">
                <span class="client--details-header">{{quotationData.clientInfo.name}}</span>
              </div>
            </div>
            <div v-if="quotationData.clientInfo.phone" class="row">
              <div class="col-sm-12 client--details">
                <i class="fa-solid fa-phone"></i>
                <span>{{quotationData.clientInfo.phone ?? "-"}}</span>
              </div>
            </div>
            <div v-if="quotationData.clientInfo.email" class="row">
              <div class="col-sm-12 client--details">
                <i class="fa-solid fa-envelope"></i>
                <span>{{quotationData.clientInfo.email ?? "-"}}</span>
              </div>
            </div>
          </div>
          <div v-if="quotationData.servicePacks && quotationData.servicePacks.length > 0"
               class="service-pack justify-content-center">
            <div v-for="(pack, index) in quotationData.servicePacks"
                 :key="index" class="service-pack--row">
              <div class="row">
                <div class="col-sm-12">
                  <h2 class="service-pack--header">
                    <span class="service-pack--name">{{ pack.packName }}</span>
                    <span class="badge badge-soft-secondary service-pack--frequency">{{ ServicePackFrequency[pack.frequency] }} Purchase</span>
                    <span class="service-pack--total-price">
                      {{ Filters.Currency(pack.items.reduce((sum, current) => sum + current.price, 0)) }}
                    </span>
                  </h2>
                </div>
              </div>
              <div v-if="pack.items && pack.items.length > 0">
                <div v-for="(service, index) in pack.items"
                     :key="index">
                  <div class="row service-pack--item">
                    <div class="col-sm-12">
                      <span class="service-pack--item-name">
                        {{ service.serviceName }}
                      </span>
                      <span class="service-pack--item-price">
                        {{ Filters.Currency(service.price) }}
                      </span>
                      <span class="service-pack--item-description">
                        {{ service.description }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="quotation-summary">
              <div v-if="(quotationTax && quotationTax > 0) || this.appliedOfferAmount > 0" class="row">
                <div class="col-sm-12">
                  <span class="quotation-summary--line-title">
                    Total Price
                  </span>
                  <span class="quotation-summary--line-amount" :class="{ 'discounted': this.appliedOfferAmount > 0 }">
                    {{ Filters.Currency(this.quotationExclTax) }}
                  </span>
                </div>
              </div>
              <div v-if="appliedOffer && appliedOffer.offerCode" class="row">
                <div class="col-sm-12">
                  <span class="quotation-summary--line-title">
                    Applied Offer
                    <span class="quotation-summary--offer-tag">
                      <i class="fa-solid fa-tag"></i>
                      {{ this.appliedOffer.offerCode }}
                    </span>
                  </span>
                </div>
              </div>
              <div v-if="appliedOfferAmount && appliedOfferAmount > 0" class="row">
                <div class="col-sm-12">
                  <span class="quotation-summary--line-title">
                    Discounted Price
                  </span>
                  <span class="quotation-summary--line-amount">
                    {{ Filters.Currency(this.quotationExclTaxDiscounted) }}
                  </span>
                </div>
              </div>
              <div v-if="quotationTax && quotationTax > 0" class="row">
                <div class="col-sm-12">
                  <span class="quotation-summary--line-title">
                    GST ({{ this.quotationData.gstPercent }}%)
                  </span>
                  <span class="quotation-summary--line-amount">
                    {{ Filters.Currency(this.quotationTax) }}
                  </span>
                </div>
              </div>
              <div class="row quotation-summary--final">
                <div class="col-sm-12 quotation-summary--final-col">
                  <span class="quotation-summary--line-title">
                    Final Price
                  </span>
                  <span class="quotation-summary--line-amount">
                    {{ Filters.Currency(this.finalPrice) }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div v-if="quotationData.addOnServicePacks && quotationData.addOnServicePacks.length > 0"
               class="service-pack justify-content-center">
            <div v-for="(pack, index) in quotationData.addOnServicePacks"
                 :key="index" class="service-pack--row">
              <div class="row">
                <div class="col-sm-12">
                  <h2 class="service-pack--header">
                    [Add-On] {{ pack.packName }}
                    <span class="badge badge-soft-secondary service-pack--frequency">{{ ServicePackFrequency[pack.frequency] }} Purchase</span>
                    <span class="service-pack--total-price">
                      {{ Filters.Currency(pack.items.reduce((sum, current) => sum + current.price, 0)) }}
                    </span>
                  </h2>
                </div>
              </div>
              <div v-if="pack.items && pack.items.length > 0">
                <div v-for="(service, index) in pack.items"
                     :key="index">
                  <div class="row service-pack--item">
                    <div class="col-sm-12">
                      <span class="service-pack--item-name">
                        {{ service.serviceName }}
                      </span>
                      <span class="service-pack--item-price">
                        {{ Filters.Currency(service.price) }}
                      </span>
                      <span class="service-pack--item-description">
                        {{ service.description }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer :is-pdf="true" />
    </div>
  </div>
</template>
