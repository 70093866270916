import { quotationMapping } from "../../features/quotation/_static/QuotationMapping";
import { IQuotationModel } from "../../features/quotation/QuotationModel";
import { IOfferModel } from "../../features/offers/OfferModel";
import { offers } from "../../features/offers/_static/Offers";

export class QuotationService {
  public static GetMappedQuotation(urlId: string): IQuotationModel | null {
    const mappingItem = quotationMapping.filter(q => q.urlId === urlId);
    if(mappingItem == null || mappingItem.length <= 0) {
      return null;
    }
    return mappingItem[0].quotation;
  }

  public static GeOffer(code: string): IOfferModel | null {
    const filteredOffer = offers.filter(o => o.offerCode == code)
    if(filteredOffer == null || filteredOffer.length <= 0) {
      return null;
    }
    return filteredOffer[0];
  }
}
