
import { Options, Vue } from "vue-class-component";

@Options({
  props: { },
})
export default class App extends Vue {
  loading: boolean = true;
  created() {
    setTimeout(() => (this.loading = false), 800);
  }
}
