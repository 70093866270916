
import { Options, Vue } from "vue-class-component";
import {IPricingItemModel} from "../PricingModel";
import {PropType} from "vue";

@Options({
  props: {
    title: String,
    pricingItems: {
      type: Array as PropType<IPricingItemModel[]>,
      default: () => []
    }
  },
})
export default class PricingGrid extends Vue {
  formatter = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0 });
}
