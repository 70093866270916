import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_PricingGrid = _resolveComponent("PricingGrid")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Navbar, {
      "is-nav-light": false,
      "is-logo-dark": true,
      "full-link": true
    }),
    _createElementVNode("div", null, [
      _createVNode(_component_PricingGrid, {
        title: "Static Website Plans",
        "pricing-items": _ctx.staticWebsitePricing()
      }, null, 8, ["pricing-items"]),
      _createVNode(_component_Footer)
    ])
  ]))
}