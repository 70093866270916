<script>
import { Options, Vue } from "vue-class-component";
import Navbar from "../../features/static/components/Navbar.vue";
import Services from "../../features/static/components/Services.vue";
import Features from "../../features/static/components/Features.vue";
import Contact from "../../features/static/components/Contact.vue";
import Footer from "../../features/static/components/Footer.vue";
import Hero from "../../features/static/components/Hero.vue";
import { inject } from "vue";

@Options({
  props: {
    section: {
      type: String,
      optional: true
    }
  },
  components: {
    Hero,
    Navbar,
    Services,
    Features,
    Contact,
    Footer
  }
})
export default class HomeView extends Vue {
  mounted() {
    if(this.section) {
      const myEl = document.getElementById(this.section);
      if(myEl) {
        const smoothScroll = inject('smoothScroll')
        smoothScroll({
          scrollTo: myEl,
          offset: this.section == "contact" ? 125: -100
        })
      }
    }
  }
}
</script>

<template>
  <div>
    <Navbar />
    <div>
      <Hero></Hero>
      <Services />
      <Features />
      <Contact />
      <Footer />
    </div>
  </div>
</template>
