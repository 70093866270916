import { IQuotationModel, ServicePackFrequency } from "../../../../features/quotation/QuotationModel";

export const ABQT1007: IQuotationModel = {
  quotationNumber: "ABQT-1007",
  quotationDate: '28th Nov 2023',
  clientInfo: {
    name: "Webmep (500 Cards in 1 Year Projection) [INR 225 per card]",
    phone: "+91-8848270048"
  },
  gstPercent: 18,
  //appliedOfferCode: "LAUNCH-2000",
  servicePacks: [
    {
      packName: "Website Hosting",
      frequency: ServicePackFrequency.Yearly,
      items: [
        {
          serviceName: "Web Hosting Service",
          description: "This service provides enough room on the internet for your website and can handle a good number of visitors. We'll link your website address (domain) to this space. If you don't have a website address, we can assist you in getting one at the regular market price.",
          price: 8000
        }
      ]
    },
    {
      packName: "Business Card Website",
      frequency: ServicePackFrequency.OneTime,
      items: [
        {
          serviceName: "Website User Interface Service",
          description: "This includes building your entire website from the ground up using the latest and most popular technology stack. We'll handle everything from start to finish.",
          price: 32000
        },
        {
          serviceName: "Themes Designs (5 different designs)",
          description: "This offers 5 themes so that you can choose which one to use for which client, using themes you can change the color palettes & images (not the structure of webpage)",
          price: 5000
        },
        {
          serviceName: "Add Business Card upto 500 to website",
          description: "This service covers introducing new cards to the website or updating existing cards",
          price: 50000
        }
      ]
    }
  ]
}
