import {
  IQuotationModel,
  ServicePackFrequency
} from "../../../../features/quotation/QuotationModel";

export const ABQT1003: IQuotationModel = {
  quotationNumber: "ABQT-1003",
  quotationDate: '13th Sept 2023',
  clientInfo: {
    name: "Absquar Solutions",
    phone: "+91-8618795392",
    email: "info@absquar.com",
  },
  gstPercent: 18,
  appliedOfferCode: "LAUNCH-2000",
  servicePacks: [
    {
      packName: "Social Media Optimisation Pack",
      frequency: ServicePackFrequency.OneTime,
      items: [
        {
          serviceName: "Optimisation Service",
          description: "This service involves setting up different social media accounts for you and creating engaging content to kickstart your online presence.",
          price: 4700
        }
      ]
    },
    {
      packName: "Organic Marketing Pack",
      frequency: ServicePackFrequency.Monthly,
      items: [
        {
          serviceName: "Handling Service",
          description: "This service covers managing all your social media accounts (like Instagram, Facebook, LinkedIn, and YouTube). We'll post content every other day to ensure a consistent and active online presence on these platforms.",
          price: 8000
        }
      ]
    }
  ]
}
