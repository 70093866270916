import { IQuotationModel, ServicePackFrequency } from "../../../../features/quotation/QuotationModel";

export const ABQT1008: IQuotationModel = {
  quotationNumber: "ABQT-1008",
  quotationDate: '6th Jan 2024',
  clientInfo: {
    name: "Girly Colours",
    phone: "+91-8122877594"
  },
  gstPercent: 18,
  servicePacks: [
    {
      packName: "eCommerce Admin Solution",
      frequency: ServicePackFrequency.Yearly,
      items: [
        {
          serviceName: "eCommerce Solution with Admin Dashboard & Payment Gateway",
          description: "This includes the charges for the third party eCommerce Solution including Payment Gateway",
          price: 20000
        }
      ]
    },
    {
      packName: "Customer Facing eCommerce Website",
      frequency: ServicePackFrequency.OneTime,
      items: [
        {
          serviceName: "Website User Interface",
          description: "This includes building your entire website from the ground up using the latest and most popular technology stack. We'll handle everything from start to finish.",
          price: 20000
        },
        {
          serviceName: "eCommerce Integration",
          description: "This includes integrating the user facing website with the third party eCommerce solution",
          price: 10000
        },
        {
          serviceName: "Payment Gateway Integration",
          description: "This includes integrating the user facing website with the third party Payment gateway solution",
          price: 10000
        }
      ]
    }
  ]
}
