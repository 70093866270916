import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSmoothScroll from 'vue3-smooth-scroll'
import "../src/assets/scss/style.scss";

let app = createApp(App);

app.use(store);
app.use(router);
app.use(VueSmoothScroll, {
  duration: 300,
  updateHistory: false,
  offset: -100
});

app.mount("#app");
