import { RouteRecordRaw } from "vue-router";
import DigitalMediaPricingView from "@/features/pricing/DigitalMediaPricingView.vue";
import StaticPricingView from "@/features/pricing/StaticPricingView1.vue";

export const planRoutes: Array<RouteRecordRaw> = [
  {
    path: "/plan/digital-media",
    name: "plan-digital-media",
    component: DigitalMediaPricingView,
  },
  {
    path: "/plan/static-website",
    name: "plan-static-website",
    component: StaticPricingView,
  }
];
